






































































































































































































































import { Component, Emit, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import APP_UTILITIES from '@/utilities/commonFunctions';
import ProgramsModule from '@/store/modules/Programs/module';
import SessionsModule from '@/store/modules/Sessions/module';
import OffCanvas from '@/commoncomponents/OffCanvas.vue';
import DatepickerComponent from '@/ui-components/datepicker/DatepickerComponent.vue';
import DropdownList from '@/ui-components/dropdownListBx/DropdownList.vue';
import { DropdownListItem, DropdownListOptions } from '@/ui-components/dropdownListBx/types';
import ProgressButton from '@/commoncomponents/progressButton/progressButton.vue';
import { AddEditProgramData, AddEditProgramPayload, ProgramType, AddEditProgramDataFormField, AddEditProgramFormData } from '@/Model/programs/types';
import CancelConfirmation from '@/popupcomponents/cancelConfirmation/CancelConfirmation.vue';
import APP_CONST from '@/constants/AppConst';
import BouncingPreloader from '@/commoncomponents/bouncingpreloadercomponent/BouncingPreloaderComponent.vue';
import { ApiErrorType } from '@/Model/errors/api/types';
import { FormFieldValidation, FormValidation, FormValidationError } from '@/Model/forms/types';

@Component({
  components: {
    OffCanvas,
    DatepickerComponent,
    DropdownList,
    ProgressButton,
    CancelConfirmation,
    BouncingPreloader
  }
})
export default class AddEditProgram extends Vue {

  @Prop({ type: Boolean, default: false }) readonly isEditMode!: boolean;
  @PropSync('isAddEditProgramVisible', { type: Boolean, required: true }) isAddEditProgramVisibleSync!: boolean;

  @Emit('onProgramUpdate')
  onProgramUpdate(programId: number) {
    return programId;
  }

  readonly screenText = new ScreenText();
  readonly programsModule = ProgramsModule;
  readonly sessionsModule = SessionsModule;
  readonly programNameMaxLength = 100;
  readonly programEnrollmentGoalMin = 1;
  readonly programEnrollmentGoalMax = 10000000;
  readonly programEnrollmentGoalMaxLength = 8;
  readonly programDescriptionMaxLength = 500;
  readonly defaultInitialProgramData: AddEditProgramData = {
    programId: 0,
    programName: '',
    programStartDate: null,
    programEndDate: null,
    programType: null,
    programEnrollmentGoal: '',
    programDescription: ''
  };

  isValidForProgressButton = true;
  isCancelConfirmationVisible = false;
  skipCancelConfirmation = false;
  hasUserSubmitted = false;
  programData: AddEditProgramData = { ...this.initialProgramData };

  get previousProgramTypeId(): number {
    return (this.initialProgramData.programType && this.initialProgramData.programType.id) || -1;
  }

  get initialProgramData(): AddEditProgramData {
    const storeProgramData = this.programsModule.programData;
    if (!this.isEditMode || !storeProgramData || !storeProgramData.programDto) {
      return this.defaultInitialProgramData;
    }
    let startDate: Date | null = null;
    let endDate: Date | null = null;
    try {
      startDate = new Date(storeProgramData.programDto.startDate);
      endDate = new Date(storeProgramData.programDto.endDate);
    }
    catch (error) {
      console.log('Error converting initial program data:', error);
    }
    const programType: ProgramType | null = this.programsModule.programTypes
      .find(pt => pt.id === storeProgramData.programDto.productId) || null;
    return {
      programId: storeProgramData.programDto.id,
      programName: storeProgramData.programDto.name,
      programStartDate: startDate,
      programEndDate: endDate,
      programEnrollmentGoal: storeProgramData.programDto.numberOfScholars,
      programType: programType,
      programDescription: storeProgramData.programDto.description
    };
  }

  get formTitle(): string {
    return this.isEditMode
      ? this.screenText.getScreenText('EDIT_PROGRAM_TITLE')
      : this.screenText.getScreenText('ADD_PROGRAM_TITLE');
  }

  get programNameLabel(): string {
    return this.screenText.getScreenText('ADD_EDIT_PROGRAM_NAME');
  }

  get programStartDateLabel(): string {
    return this.screenText.getScreenText('ADD_EDIT_PROGRAM_START_DATE');
  }

  get programEndDateLabel(): string {
    return this.screenText.getScreenText('ADD_EDIT_PROGRAM_END_DATE');
  }

  get programSessionDateConflictHint(): string {
    return this.screenText.getScreenText('ADD_EDIT_PROGRAM_SESSION_DATE_CONFLICT_HINT');
  }

  get programEnrollmentGoalLabel(): string {
    return this.screenText.getScreenText('ADD_EDIT_PROGRAM_ENROLLMENT_GOAL');
  }

  get programEnrollmentGoalQualifierLabel(): string {
    return this.screenText.getScreenText('ADD_EDIT_PROGRAM_ENROLLMENT_INPUT_QUALIFIER');
  }

  get programTypeLabel(): string {
    return this.screenText.getScreenText('ADD_EDIT_PROGRAM_TYPE');
  }

  get programDescriptionLabel(): string {
    return this.screenText.getScreenText('ADD_EDIT_PROGRAM_DESCRIPTION');
  }

  get cancelButtonText(): string {
    return this.screenText.getScreenText('BTN_CANCEL');
  }

  get saveButtonText(): string {
    return this.screenText.getScreenText('BTN_SAVE');
  }

  get programTypeDropdownListOptions(): DropdownListOptions {
    const dropdownList: Array<DropdownListItem> = this.programsModule.programTypes.map((programType) => ({
      id: programType.id,
      value: programType.name
    }));
    const programTypeId = (this.programData.programType && this.programData.programType.id) || 0;
    const programTypeName = (this.programData.programType && this.programData.programType.name) || this.screenText.getScreenText('ADD_EDIT_PROGRAM_TYPE_DEFAULT_SELECT');
    return {
      id: programTypeId,
      singleSelect: true,
      showSelectLabel: true,
      value: programTypeName,
      dropdownList,
      error: this.shouldApplyErrorClassForFormField('programType'),
      errorText: this.shouldShowErrorForFormField('programType')
        ? this.formFieldStates.programType.errorMessage
        : ''
    };
  }

  get programStartDateMax(): Date | null {
    if (this.isEditMode) {
      const sessionStartDateTimes = this.sessionsModule.sessions.map(session => new Date(session.startDateTime));
      const sessionStartDateTimesExist = (sessionStartDateTimes || []).length > 0;
      if (sessionStartDateTimesExist) {
        const earliestSessionStartDate = sessionStartDateTimes.reduce((prev, cur) => cur.getTime() < prev.getTime()
          ? cur
          : prev, sessionStartDateTimes[0]);
        return earliestSessionStartDate;
      }
    }
    const programEndDate = this.programData.programEndDate;
    if (programEndDate && programEndDate instanceof Date) {
      return programEndDate;
    }
    return null;
  }

  get programEndDateMin(): Date | null {
    if (this.isEditMode) {
      const sessionEndDateTimes = this.sessionsModule.sessions.map(session => new Date(session.endDateTime));
      const sessionEndDateTimesExist = (sessionEndDateTimes || []).length > 0;
      if (sessionEndDateTimesExist) {
        const furthestOutSessionEndDate = sessionEndDateTimes.reduce((prev, cur) => cur.getTime() > prev.getTime()
          ? cur
          : prev, sessionEndDateTimes[0]);
        return furthestOutSessionEndDate;
      }
    }
    const programStartDate = this.programData.programStartDate;
    if (programStartDate && programStartDate instanceof Date) {
      return programStartDate;
    }
    return null;
  }

  get footerErrorMessage(): string {
    let message = '';
    if (this.programsModule.saveProgramFailedError) {
      const isProgramNameDuplicateApiError = this.programsModule.saveProgramFailedError.name === ApiErrorType.Error_ProgramNameExistsInAccount;
      if (isProgramNameDuplicateApiError && this.formFieldStates.programName.error === FormValidationError.DUPLICATE) {
        message = this.programsModule.saveProgramFailedError.value;
      }
      const isUnknownApiError = this.programsModule.saveProgramFailedError.name === ApiErrorType.Error_Unknown;
      if (isUnknownApiError) {
        message = this.programsModule.saveProgramFailedError.value;
      }
    }
    if (!message && this.hasUserSubmitted && (this.doAnyFormFieldsHaveSpecificError(FormValidationError.REQUIRED))) {
      message = this.screenText.getScreenText('ALL_FIELDS_MANDATORY');
    }
    return message;
  }

  get startDateOpenDate(): Date {
    return this.programData.programStartDate || this.programData.programEndDate || new Date();
  }

  get endDateOpenDate(): Date {
    return this.programData.programEndDate || this.programData.programStartDate || new Date();
  }

  get startDateDatepickerInputClass(): string {
    return this.getDatepickerInputClass('programStartDate');
  }

  get endDateDatepickerInputClass(): string {
    return this.getDatepickerInputClass('programEndDate');
  }

  get saveProgressButtonState(): number {
    if (!this.programsModule.isSavingProgram && !this.programsModule.didSaveProgramFail) {
      return 200;
    }
    else if (!this.programsModule.isSavingProgram && this.programsModule.didSaveProgramFail) {
      return 400;
    }
    return 0;
  }

  get shouldShowDateRangeSessionHint(): boolean {
    return (!this.shouldShowErrorForFormField('programStartDate') && !this.shouldShowErrorForFormField('programEndDate'));
  }

  get shouldApplyErrorClassForProgramName(): boolean {
    return this.shouldApplyErrorClassForFormField('programName');
  }

  get shouldApplyErrorClassForProgramEnrollmentGoal(): boolean {
    return this.shouldApplyErrorClassForFormField('programEnrollmentGoal');
  }

  get shouldApplyErrorClassForProgramType(): boolean {
    return this.shouldApplyErrorClassForFormField('programType');
  }

  get shouldApplyErrorClassForProgramDescription(): boolean {
    return this.shouldApplyErrorClassForFormField('programDescription');
  }

  get shouldShowErrorForProgramName(): boolean {
    if (
      this.programsModule.saveProgramFailedError &&
      this.programsModule.saveProgramFailedError.name === ApiErrorType.Error_ProgramNameExistsInAccount &&
      this.formFieldStates.programName.error === FormValidationError.DUPLICATE &&
      this.shouldApplyErrorClassForFormField('programName')
    ) {
      return true;
    }
    return this.shouldShowErrorForFormField('programName');
  }

  get shouldShowErrorForProgramStartDate(): boolean {
    return this.shouldShowErrorForFormField('programStartDate');
  }

  get shouldShowErrorForProgramEndDate(): boolean {
    return this.shouldShowErrorForFormField('programEndDate');
  }

  get shouldShowErrorForProgramEnrollmentGoal(): boolean {
    return this.shouldShowErrorForFormField('programEnrollmentGoal');
  }

  get shouldShowErrorForProgramType(): boolean {
    return this.shouldShowErrorForFormField('programType');
  }

  get shouldShowErrorForProgramDescription(): boolean {
    return this.shouldShowErrorForFormField('programDescription');
  }

  // Form validation -- ideally should be done via a library
  formFieldStates: FormFieldValidation<AddEditProgramFormData> = {
    programName: this.getDefaultFormFieldValidationState(),
    programStartDate: this.getDefaultFormFieldValidationState(),
    programEndDate: this.getDefaultFormFieldValidationState(),
    programEnrollmentGoal: this.getDefaultFormFieldValidationState(),
    programType: this.getDefaultFormFieldValidationState(),
    programDescription: this.getDefaultFormFieldValidationState(),
  };

  @Watch('programsModule.saveProgramFailedError')
  onSaveProgramFailedErrorChanged(saveProgramFailedError: typeof ProgramsModule.saveProgramFailedError) {
    if (saveProgramFailedError && saveProgramFailedError.name === ApiErrorType.Error_ProgramNameExistsInAccount) {
      const formFieldState = this.formFieldStates.programName;
      formFieldState.error = FormValidationError.DUPLICATE;
      formFieldState.errorMessage = saveProgramFailedError.value;
      formFieldState.valid = false;
    }
  }

  created() {
    this.programsModule.fetchProgramTypes().then(() => {
      if (this.isEditMode) {
        this.programData = {
          ...this.programData,
          programType: this.initialProgramData.programType
        };
      }
    });
  }

  forceNumberInput = APP_UTILITIES.forceNumberInput;

  getDefaultFormFieldValidationState(): FormValidation {
    return {
      changes: false,
      dirty: false,
      error: null,
      errorMessage: '',
      valid: false
    };
  }

  checkFormFieldChanges(field: AddEditProgramDataFormField) {
    const formFieldState = this.formFieldStates[field];
    let initial = this.initialProgramData[field];
    let current = this.programData[field];
    if (typeof initial === 'string') {
      initial = initial.trim();
    }
    if (typeof current === 'string') {
      current = current.trim();
    }
    let hasChanges = (initial !== current);

    // Special condition for enrollment goal because if input is erased
    // it is modelled as an empty string rather than a number, so we
    // instead check if both are falsy, meaning no changes occurred
    if (field === 'programEnrollmentGoal' && !initial && !current) {
      hasChanges = false;
    }

    formFieldState.changes = hasChanges;

    if (hasChanges && this.hasUserSubmitted) {
      this.checkFormValid();
    }
  }

  onInputProgramName() {
    const formFieldState = this.formFieldStates.programName;
    if (!formFieldState.dirty) {
      formFieldState.dirty = true;
    }
    this.checkFormFieldChanges('programName');
  }

  checkProgramNameValid() {
    const programName = this.programData.programName;
    const formFieldState = this.formFieldStates.programName;
    if (!programName || !programName.trim() || !programName.trim().length) {
      formFieldState.valid = false;
      formFieldState.error = FormValidationError.REQUIRED;
      formFieldState.errorMessage = `${this.screenText.getScreenText('ADD_EDIT_PROGRAM_NAME')} ${this.screenText.getScreenText('FORM_VALIDATION_REQUIRED_PREDICATE')}.`;
    }
    else if (programName.length > this.programNameMaxLength) {
      formFieldState.valid = false;
      formFieldState.error = FormValidationError.MAX_LENGTH;
      formFieldState.errorMessage = `${this.screenText.getScreenText('ADD_EDIT_PROGRAM_NAME')} ${this.screenText.getScreenText('FORM_VALIDATION_MAX_LENGTH_PREDICATE')} ${this.programNameMaxLength}.`;
    }
    else {
      formFieldState.valid = true;
      formFieldState.error = null;
      formFieldState.errorMessage = '';
    }
  }

  onChangeProgramStartDate(startDate: Date) {
    this.programData.programStartDate = startDate;
    const formFieldState = this.formFieldStates.programStartDate;
    if (!formFieldState.dirty) {
      formFieldState.dirty = true;
    }
    this.checkFormFieldChanges('programStartDate');
  }

  checkProgramStartDateValid() {
    const programStartDate = this.programData.programStartDate;
    const formFieldState = this.formFieldStates.programStartDate;
    if (!programStartDate) {
      formFieldState.valid = false;
      formFieldState.error = FormValidationError.REQUIRED;
      formFieldState.errorMessage = `${this.screenText.getScreenText('ADD_EDIT_PROGRAM_START_DATE')} ${this.screenText.getScreenText('FORM_VALIDATION_REQUIRED_PREDICATE')}.`;
    }
    else {
      formFieldState.valid = true;
      formFieldState.error = null;
      formFieldState.errorMessage = '';
    }
  }

  onChangeProgramEndDate(endDate: Date) {
    this.programData.programEndDate = endDate;
    const formFieldState = this.formFieldStates.programEndDate;
    if (!formFieldState.dirty) {
      formFieldState.dirty = true;
    }
    this.checkFormFieldChanges('programEndDate');
  }

  checkProgramEndDateValid() {
    const programStartDate = this.programData.programStartDate;
    const programEndDate = this.programData.programEndDate;
    const formFieldState = this.formFieldStates.programEndDate;
    if (!programEndDate) {
      formFieldState.valid = false;
      formFieldState.error = FormValidationError.REQUIRED;
      formFieldState.errorMessage = `${this.screenText.getScreenText('ADD_EDIT_PROGRAM_END_DATE')} ${this.screenText.getScreenText('FORM_VALIDATION_REQUIRED_PREDICATE')}.`;
    }
    else if (programStartDate && programStartDate > programEndDate) {
      formFieldState.valid = false;
      formFieldState.error = FormValidationError.MIN;
      formFieldState.errorMessage = `${this.screenText.getScreenText('ADD_EDIT_PROGRAM_END_DATE')} ${this.screenText.getScreenText('FORM_VALIDATION_GREATER_THAN_PREDICATE')} ${this.screenText.getScreenText('ADD_EDIT_PROGRAM_START_DATE')}.`;
    }
    else {
      formFieldState.valid = true;
      formFieldState.error = null;
      formFieldState.errorMessage = '';
    }
  }

  onInputProgramEnrollmentGoal() {
    const formFieldState = this.formFieldStates.programEnrollmentGoal;
    if (!formFieldState.dirty) {
      formFieldState.dirty = true;
    }
    this.checkFormFieldChanges('programEnrollmentGoal');
  }

  checkProgramEnrollmentGoalValid() {
    const programEnrollmentGoal = this.programData.programEnrollmentGoal;
    const formFieldState = this.formFieldStates.programEnrollmentGoal;
    if (programEnrollmentGoal === undefined || programEnrollmentGoal === null || programEnrollmentGoal === '') {
      formFieldState.valid = false;
      formFieldState.error = FormValidationError.REQUIRED;
      formFieldState.errorMessage = `${this.screenText.getScreenText('ADD_EDIT_PROGRAM_ENROLLMENT_GOAL')} ${this.screenText.getScreenText('FORM_VALIDATION_REQUIRED_PREDICATE')}.`;
    }
    else if (typeof programEnrollmentGoal !== 'number') {
      formFieldState.valid = false;
      formFieldState.error = FormValidationError.TYPE;
      formFieldState.errorMessage = `${this.screenText.getScreenText('ADD_EDIT_PROGRAM_ENROLLMENT_GOAL')} ${this.screenText.getScreenText('FORM_VALIDATION_NUMBER_ONLY_PREDICATE')}.`;
    }
    else if (programEnrollmentGoal > this.programEnrollmentGoalMax) {
      formFieldState.valid = false;
      formFieldState.error = FormValidationError.MAX;
      formFieldState.errorMessage = `${this.screenText.getScreenText('ADD_EDIT_PROGRAM_ENROLLMENT_GOAL')} ${this.screenText.getScreenText('FORM_VALIDATION_LESS_THAN_EQUAL_TO_PREDICATE')} ${this.programEnrollmentGoalMax}.`;
    }
    else if (programEnrollmentGoal < this.programEnrollmentGoalMin) {
      formFieldState.valid = false;
      formFieldState.error = FormValidationError.MIN;
      formFieldState.errorMessage = `${this.screenText.getScreenText('ADD_EDIT_PROGRAM_ENROLLMENT_GOAL')} ${this.screenText.getScreenText('FORM_VALIDATION_GREATER_THAN_PREDICATE')} ${this.programEnrollmentGoalMin - 1}.`;
    }
    else {
      formFieldState.valid = true;
      formFieldState.error = null;
      formFieldState.errorMessage = '';
    }
  }

  onSelectProgramType(selection: DropdownListItem) {
    this.programData.programType = this.programsModule.programTypes.find(pt => pt.id === selection.id) || null;
    const formFieldState = this.formFieldStates.programType;
    if (!formFieldState.dirty) {
      formFieldState.dirty = true;
    }
    this.checkFormFieldChanges('programType');
  }

  checkProgramTypeValid() {
    const programType = this.programData.programType;
    const formFieldState = this.formFieldStates.programType;
    if (!programType) {
      formFieldState.valid = false;
      formFieldState.error = FormValidationError.REQUIRED;
      formFieldState.errorMessage = `${this.screenText.getScreenText('ADD_EDIT_PROGRAM_TYPE')} ${this.screenText.getScreenText('FORM_VALIDATION_REQUIRED_PREDICATE')}.`;
    }
    else {
      formFieldState.valid = true;
      formFieldState.error = null;
      formFieldState.errorMessage = '';
    }
  }

  onInputProgramDescription() {
    const formFieldState = this.formFieldStates.programDescription;
    if (!formFieldState.dirty) {
      formFieldState.dirty = true;
    }
    this.checkFormFieldChanges('programDescription');
  }

  checkProgramDescriptionValid() {
    const programDescription = this.programData.programDescription;
    const formFieldState = this.formFieldStates.programDescription;
    if (programDescription && programDescription.trim() && programDescription.length > this.programDescriptionMaxLength) {
      formFieldState.valid = false;
      formFieldState.error = FormValidationError.MAX_LENGTH;
      formFieldState.errorMessage = `${this.screenText.getScreenText('ADD_EDIT_PROGRAM_DESCRIPTION')} ${this.screenText.getScreenText('FORM_VALIDATION_MAX_LENGTH_PREDICATE')} ${this.programDescriptionMaxLength}.`;
    }
    else {
      formFieldState.valid = true;
      formFieldState.error = null;
      formFieldState.errorMessage = '';
    }
  }

  onCancel() {
    this.isAddEditProgramVisibleSync = false;
  }

  onBeforeClose(event: Event) {
    if (this.programsModule.isSavingProgram) {
      event.preventDefault();
    }
    else if (this.hasFormChanges() && !this.skipCancelConfirmation) {
      this.isCancelConfirmationVisible = true;
      event.preventDefault();
    }
  }

  onClose() {
    this.isAddEditProgramVisibleSync = false;
    this.skipCancelConfirmation = false;
    this.resetForm();
    this.programsModule.RESET_SAVE_PROGRAM();
  }

  onContinueConfirmation() {
    this.isCancelConfirmationVisible = false;
    this.onClose();
  }

  async onSubmit() {
    this.hasUserSubmitted = true;
    this.programsModule.RESET_SAVE_PROGRAM();
    const allowSubmit = !this.programsModule.isSavingProgram && this.isFormValid() && this.hasFormChanges();
    if (!allowSubmit) {
      return;
    }

    const prepDate = (date: Date | null) => {
      return date
        ? APP_UTILITIES.formatFullYearDate(date.toISOString())
        : '';
    };
    const accountIdCookie = APP_UTILITIES.getCookie('accountId');
    const accountId = accountIdCookie
      ? Number.parseInt(accountIdCookie)
      : 0;
    const productId = (this.programData.programType && this.programData.programType.id) || 0;
    const startDate = prepDate(this.programData.programStartDate);
    const endDate = prepDate(this.programData.programEndDate);
    const numberOfScholars = this.programData.programEnrollmentGoal || 0;
    const initialStatus = (this.programsModule.programData && this.programsModule.programData.programDto.status) || 1;

    // Many of these values are hardcoded in the previous implementation
    const payload: AddEditProgramPayload = {
      programAdminId: 0, // Hardcoded `0` in original implementation
      isFinalSubmit: true, // Originally took the value of a component prop of the same name, but always got set to `true` during validation
      validationMode: this.isEditMode
        ? 2
        : 0,
      isProgramDateChanged: true, // Hardcoded `true` in original implementation
      programDto: {
        id: this.programData.programId,
        roleId: 0, // Hardcoded `0` in original implementation
        accId: accountId,
        name: this.programData.programName,
        productId,
        description: this.programData.programDescription,
        startDate,
        endDate,
        numberOfScholars,
        previousProductId: this.previousProgramTypeId,
        status: initialStatus,
      }
    };
    this.isValidForProgressButton = false;
    if (this.isEditMode) {
      await this.programsModule.updateProgram(payload);
      if (!this.programsModule.didSaveProgramFail) {
        this.isValidForProgressButton = true;
        this.programsModule.RESET_FETCH_PROGRAM_DATA();
        this.onProgramUpdate(payload.programDto.id);
        this.skipCancelConfirmation = true;
        this.isAddEditProgramVisibleSync = false;
        this.onClose();
      }
    }
    else {
      await this.programsModule.createProgram(payload);
      if (!this.programsModule.didSaveProgramFail) {
        this.isValidForProgressButton = true;
        this.skipCancelConfirmation = true;
        this.programsModule.RESET_FETCH_PROGRAM_DATA();
        this.$router.push(APP_CONST.APP_ROUTES.ACCOUNT_PROGRAMS_SESSIONS);
      }
    }
  }

  checkFormValid() {
    this.checkProgramNameValid();
    this.checkProgramStartDateValid();
    this.checkProgramEndDateValid();
    this.checkProgramEnrollmentGoalValid();
    this.checkProgramTypeValid();
    this.checkProgramDescriptionValid();
  }

  resetForm() {
    this.hasUserSubmitted = false;
    this.programData = { ...this.initialProgramData };
    this.formFieldStates = {
      programName: this.getDefaultFormFieldValidationState(),
      programStartDate: this.getDefaultFormFieldValidationState(),
      programEndDate: this.getDefaultFormFieldValidationState(),
      programEnrollmentGoal: this.getDefaultFormFieldValidationState(),
      programType: this.getDefaultFormFieldValidationState(),
      programDescription: this.getDefaultFormFieldValidationState(),
    };
    this.programsModule.SET_PROGRAM_TYPES([...this.programsModule.programTypes]); // TODO (REFACTOR): This is a workaround for now because the DropdownList component currently has no way of resetting the selection properly
  }

  isFormValid(): boolean {
    this.checkFormValid();
    for (const formFieldState of Object.values(this.formFieldStates)) {
      if (!formFieldState.valid) {
        return false;
      }
    }
    return true;
  }

  hasFormChanges(): boolean {
    for (const formFieldState of Object.values(this.formFieldStates)) {
      if (formFieldState.changes) {
        return true;
      }
    }
    return false;
  }

  doAnyFormFieldsHaveSpecificError(error: FormValidationError): boolean {
    for (const formFieldState of Object.values(this.formFieldStates)) {
      if (formFieldState.error === error) {
        return true;
      }
    }
    return false;
  }

  shouldApplyErrorClassForFormField(field: AddEditProgramDataFormField): boolean {
    const formFieldState = this.formFieldStates[field];
    if (this.hasUserSubmitted && !formFieldState.valid) {
      return true;
    }
    return false;
  }

  shouldShowErrorForFormField(field: AddEditProgramDataFormField): boolean {
    const formFieldState = this.formFieldStates[field];
    if (this.shouldApplyErrorClassForFormField(field) && formFieldState.error !== FormValidationError.REQUIRED) {
      return true;
    }
    return false;
  }

  getDatepickerInputClass(startOrEndDate: Extract<AddEditProgramDataFormField, 'programStartDate' | 'programEndDate'>): string {
    const formFieldDate = this.initialProgramData[startOrEndDate];
    if (this.isEditMode && formFieldDate) {
      const today = new Date();
      const isPastDate = today > formFieldDate;
      if (startOrEndDate === 'programEndDate' && isPastDate) {
        return 'disabled';
      }
    }
    if (this.shouldApplyErrorClassForFormField(startOrEndDate)) {
      return 'outline-red';
    }
    return '';
  }
}
