import tableStore from '@/store/modules/table';
import { Vue, Component } from 'vue-property-decorator';
import APP_CONST from '@/constants/AppConst';
import APP_UTILITIES from '@/utilities/commonFunctions';
import programListStore from '@/store/modules/programList';
import SmartTableContainer from '@/commoncomponents/smartTableComponents/SmartTableContainer.vue';
import BouncingPreloaderComponent from '@/commoncomponents/bouncingpreloadercomponent/BouncingPreloaderComponent.vue';
import ActionsDropdown from '@/commoncomponents/actionsdropdowncomponent/ActionsDropdown.vue';
import headerStore from '@/store/modules/headerStore';
import UIkit from 'uikit';
import CancelConfirmation from '@/popupcomponents/cancelConfirmation/CancelConfirmation.vue';
import ActionIgnoredPopup from '@/popupcomponents/actionIgnoredPopup/ActionIgnoredPopup.vue';
import { SearchObj, SortObjc } from '@/Model/model';
import AddEditProgram from './AddEditProgram.vue';
import ProgramsModule from '@/store/modules/Programs/module';

@Component({
  components: {
    'programs-table': SmartTableContainer,
    'bouncing-preloader': BouncingPreloaderComponent,
    'actions-dropdown': ActionsDropdown,
    'reset-task-confirmation': CancelConfirmation,
    'action-ignored-popup': ActionIgnoredPopup,
    AddEditProgram
  }
})
export default class Programs extends Vue {
    public tableHeaders = APP_CONST.PROGRAMS_TABLE_HEADER;
    public actionsDropdown: any = APP_CONST.PROGRAMS_ACTIONS_DROPDOWN;
    public totalProgramsCount: number = 0;
    public totalActiveProgramsCount: number = 0;
    public programListResponse: boolean = false;
    public roleId: number = -1;
    public isAccountAdmin: boolean = false;
    public displayColumn:any = [];
    public searchLists: any = <any>[ { header: 'programType', values: []}, { header: 'programStatus', values: ['Active', 'Inactive']} ];
    public programsList:any = [];
    public selectedPrograms: number = -1;
    public ignoredActionPrograms: string[] = [];
    public popupInstructions: {} = {first:'The action was NOT completed for the below', second: 'programs as they are ineligible for the action.'};
    public displayAll: boolean = true;
    public initialTotalItems: number = 0;
    public localConst:any = APP_CONST;
    readonly programsModule = ProgramsModule;
    isAddEditProgramVisible = false;
    private currentUserRole: number = +(APP_UTILITIES.getCookie('highest_role') || APP_CONST.ZERO);
    private allowedRolesAddProgram: Array<number> = [
      APP_CONST.ROLES.BX_SUPER_ADMIN,
      APP_CONST.ROLES.BX_ADMIN,
      APP_CONST.ROLES.ACCOUNT_ADMIN
    ];
    canAddProgram = this.allowedRolesAddProgram.some(r => r === this.currentUserRole); // TODO (REFACTOR): This will need to be reworked when role checks are centralized

    /* istanbul ignore next */
    get programsHeaders() {
      return programListStore.programsHeaders;
    }

    /* istanbul ignore next */
    get programsData() {
      return programListStore.programsList;
    }

    /* istanbul ignore next */
    get programsListAllData() {
      return programListStore.programsListAllData;
    }

    /* istanbul ignore next */
    get accountData(){
      return programListStore.accountInfo;
    }

    /* istanbul ignore next */
    get allDataSelected() {
      return tableStore.allDataSelected;
    }

    /* istanbul ignore next */
    get isLoaderVisible(){
      return (this.initialTotalItems>=this.localConst.HUNDRED || this.initialTotalItems <= this.localConst.ZERO);
    }

    /* istanbul ignore next */
    programsListData() {
      this.programsData && this.programsData.programList && this.programsData.programList.forEach((program: any) => {
        program.startDate = APP_UTILITIES.formatShortDate(program.startDate);
        program.endDate = APP_UTILITIES.formatShortDate(program.endDate);
        program.noOfSites = program.noOfSites != 0
          ? program.noOfSites
          : '0';
        program.noOfScholar = program.noOfScholar != 0
          ? program.noOfScholar
          : '0';
        program.enrolledScholars = program.enrolledScholars != 0
          ? program.enrolledScholars
          : '0';
        program.selected = false;
      });
      this.programsData && this.programsData.programList && this.programsData.programList.forEach((program: any) => {
        tableStore.allDataSelected && tableStore.allDataSelected.length && tableStore.allDataSelected.forEach((elementChecked: any) => {
          if (elementChecked.programId === program.programId && program.programName === elementChecked.programName) {
            program.selected = elementChecked.selected;
          }
        });
      });
      if (tableStore.allSelect) {
        this.programsData && this.programsData.programList && this.programsData.programList.forEach((program: any) => {
          program.selected = true;
        });
        this.programsData && this.programsData.programList && this.programsData.programList.forEach((program: any) => {
          tableStore.unSelectData && tableStore.unSelectData.length && tableStore.unSelectData.forEach((elementChecked: any) => {
            if (elementChecked.programId === program.programId && program.programName === elementChecked.programName) {
              program.selected = false;
            }
          });
        });
      }
      this.programsList = this.programsData.programList;
    }

    /* istanbul ignore next */
    get programsPayload() {
      return programListStore.currentProgramsPayload;
    }

    /* istanbul ignore next */
    get currentProgramListPayloadForFullData() {
      return programListStore.currentProgramListPayloadForFullData;
    }

    /* istanbul ignore next */
    get isActionsDD(){
      return ((this.roleId < 5 && this.roleId !== 0) || this.isAccountAdmin)
        ? true
        : false;
    }

    /* istanbul ignore next */
    get programResizesData() {
      return programListStore.programResizesData;
    }

    /* istanbul ignore next */
    get allProgramDataSelected() {
      return tableStore.allDataSelected;
    }

    /* istanbul ignore next */
    get programsSearchObj(){
      return programListStore.programsSearchObj;
    }

    /* istanbul ignore next */
    created() {
      this.setProgramTypeValues([]);
      programListStore.mutateProgramFilterObject(JSON.parse(JSON.stringify(APP_CONST.PROGRAMS_TABLE_HEADER.MOVABLE)));

      programListStore.mutateCurrentObj({ ...this.programsSearchObj, programName: '', programType: '', noOfScholar: '', enrolledScholars: '', programStatus: '', noOfSites: '', isExactMatch: false });
    }

    /* istanbul ignore next */
    beforeMount() {
      const storedAccountId: any = APP_UTILITIES.getCookie('accountId');
      programListStore.mutateProgramsPayload(JSON.parse(JSON.stringify(APP_CONST.PROGRAM_PAYLOAD)));
      programListStore.mutateProgramsPayload({ ...programListStore.currentProgramsPayload, id: parseInt(storedAccountId) });
      this.programListResponse = false;
      programListStore.mutateAllProgramListdata({ ...programListStore.currentProgramListPayloadForFullData, id: parseInt(storedAccountId) });
      programListStore.getFullProgramsData().then(() => {
        this.getProgramList().then(()=>{
          this.initialTotalItems = this.programsData.totalProgramsCount;
        });
      });
      const { roleId } = APP_UTILITIES.coreids();
      this.roleId = roleId;
      this.isAccountAdmin = Boolean(APP_UTILITIES.getCookie('isAccountAdmin'));
      this.displayColumn = programListStore.programsHeaders;
    }

    navigateToProgramAdmin(data: any) {
      /* istanbul ignore else */
      const userRoles: any = APP_UTILITIES.getCookie('user_role');
      const accountId: any = APP_UTILITIES.getCookie('accountId');
      const program_id = data.programId;
      const program_name = data.programName;
      const userData = JSON.parse(userRoles);
      userData[0].roleId = APP_CONST.PROGRAM_ADMIN_ROLE_ID;
      userData[0].accountId = accountId;
      userData[0].programId = program_id;
      headerStore.mutateBxState(true);
      APP_UTILITIES.setCookie('super_user_drilldown', 'true', 1);
      APP_UTILITIES.setCookie('user_role', JSON.stringify(userData), 1);
      APP_UTILITIES.setCookie('progName', program_name, 1);
      APP_UTILITIES.setCookie('programId', program_id, 1);
      if(this.accountData && this.accountData.accountName){
        APP_UTILITIES.setCookie('accountName', this.accountData && this.accountData.accountName, APP_CONST.ONE);
      }
      this.$router && this.$router.push('/home');
      this.$emit('loadNav', {});
    }

    async getProgramList() {
      await programListStore.getAllProgramsData().then((res: any) => {
        const programTypeList = res.data && res.data.programTypeList || [];
        this.setProgramTypeValues(programTypeList);
        this.programsListData();
        this.programListResponse = true;
      });
    }

    clearSelection() {
      tableStore.mutateSelectAll(false);
      tableStore.mutateFewCheckData('');
      tableStore.mutateAllSelectedData([]);
      tableStore.mutateUnSelectedData([]);
      tableStore.mutateSelectStatus('');
      const element = <HTMLInputElement>document.getElementById('all-data-checkbox');
      if (element) {
        element.checked = false;
      }
      this.programsList && this.programsList.length && this.programsList.forEach((element: any) => {
        element.selected = false;
      });
    }

    performAction(key: string) {
      switch (key) {
        case 'Add':
          this.addProgram();
          break;
        case 'Disable':
          this.enableDisableProgram(APP_CONST.TWO);
          break;
        case 'Enable':
          this.enableDisableProgram(APP_CONST.ONE);
          break;
        case 'Reset Tasks':
          this.getSelectedPrograms();
          break;
        default:
          break;
      }
    }

    getSelectedPrograms(){
      let unSelectedIds: any = [];
      let selectedIds:any = [];
      if (tableStore.selectAll) {
        if (tableStore.unSelectData.length) {
          unSelectedIds = tableStore.unSelectData.map((program: any) => {
            return program.programId;
          });
        }
        if (unSelectedIds && unSelectedIds.length) {
          this.selectedPrograms = this.programsData.totalProgramsCount - unSelectedIds.length;
        }
        else {
          this.selectedPrograms = this.programsData.totalProgramsCount;
        }
      }
      else{
        selectedIds = this.allDataSelected.map((program: any) => {
          return program.programId;
        });
        this.selectedPrograms = selectedIds.length;
      }

      if(this.selectedPrograms && UIkit.modal('#cancel-confirmation')) {
        UIkit.modal('#cancel-confirmation').show();
      }
    }

    closePopup(){
      if(UIkit.modal('#cancel-confirmation')) {
        UIkit.modal('#cancel-confirmation').hide();
      }
      this.clearSelection();
    }

    closeActionIgnoredPopup(){
      if(UIkit.modal('#actions-ignored-popup')) {
        UIkit.modal('#actions-ignored-popup').hide();
      }
      this.ignoredActionPrograms = [];
    }

    addProgram() {
      this.isAddEditProgramVisible = true;
    }

    async enableDisableProgram(status:number){
      const accountId: any = APP_UTILITIES.getCookie('accountId');
      const payload: any = {
        selectAllPayload: {
          page: null,
          count: null,
          id: parseInt(accountId),
          active: 0,
          sortField: this.programsPayload.sortField,
          sortDir: this.programsPayload.sortDir,
          searchField: this.programsPayload.searchField,
          search: this.programsPayload.search,
          selectedIds: [],
          notSelectedIds: [],
          isAllSelected: tableStore.selectAll,
          isExactMatch: this.programsPayload.isExactMatch
        },
        status,
      };
      let unSelectedIds: any = [];
      if (tableStore.selectAll) {
        if (tableStore.unSelectData.length) {
          unSelectedIds = tableStore.unSelectData.map((program: any) => {
            return program.programId;
          });
        }
      }
      payload.selectAllPayload.notSelectedIds = unSelectedIds;
      const programIds: any = this.allProgramDataSelected.map((program: any) => {
        return program.programId;
      });
      payload.selectAllPayload.selectedIds = programIds;
      programListStore.mutateProgramsPayload({ ...this.programsPayload, page: APP_CONST.ONE, search: decodeURIComponent(this.programsPayload.search) });
      this.programListResponse = this.isLoaderVisible
        ?false
        :this.programListResponse;
      this.ignoredActionPrograms = [];
      await programListStore.enableDisableProgram(payload).then((response: any) => {
        if (response.status == APP_CONST.RESPONSE_200) {
          this.ignoredActionPrograms = response && response.data;
          if( this.ignoredActionPrograms.length && UIkit.modal('#actions-ignored-popup')) {
            UIkit.modal('#actions-ignored-popup').show();
            const elem : any = document.getElementById('popup-content') ;
            elem.scrollTo(0, 0);
          }
          this.clearSelection();
          programListStore.getFullProgramsData().then(() => {
            this.getProgramList();
          });
        }
      });
    }

    async resetTask(){
      if(UIkit.modal('#cancel-confirmation')) {
        UIkit.modal('#cancel-confirmation').hide();
      }
      const accountId: any = APP_UTILITIES.getCookie('accountId');
      const payload: any = {
        page: null,
        count: null,
        id: parseInt(accountId),
        active: 0,
        sortField: this.programsPayload.sortField,
        sortDir: this.programsPayload.sortDir,
        searchField: this.programsPayload.searchField,
        search: this.programsPayload.search,
        selectedIds: [],
        notSelectedIds: [],
        isAllSelected: tableStore.selectAll,
        addTaskDto:[],
        isExactMatch: this.programsPayload.isExactMatch
      };
      let unSelectedIds: any = [];
      if (tableStore.selectAll) {
        if (tableStore.unSelectData.length) {
          unSelectedIds = tableStore.unSelectData.map((program: any) => {
            return program.programId;
          });
        }
      }
      payload.notSelectedIds = unSelectedIds;
      const programIds: any = this.allProgramDataSelected.map((program: any) => {
        return program.programId;
      });
      payload.selectedIds = programIds;
      const programData: any = this.allProgramDataSelected.map((program: any) => {
        return {
          programId:program.programId,
          programStartDate: program.startDate,
          programEndDate: program.endDate,
          productId:program.productId,
          programName:program.programName,
        };
      });
      if (programData.length) {
        payload.addTaskDto = programData;
      }
      else{
        payload.addTaskDto = [
          {
            'programId': 0,
            'programStartDate': '2022-06-13T11:35:19.446Z',
            'programEndDate': '2022-06-13T11:35:19.446Z',
            'productId': 0,
            'programName': 'string'
          }
        ];
      }
      programListStore.mutateProgramsPayload({ ...this.programsPayload, page: APP_CONST.ONE, search: decodeURIComponent(this.programsPayload.search) });
      this.programListResponse = this.isLoaderVisible
        ?false
        :this.programListResponse;
      await programListStore.resetTaskProgram(payload).then((response: any) => {
        if (response.status == APP_CONST.RESPONSE_200) {
          this.clearSelection();
          programListStore.getFullProgramsData().then(() => {
            this.getProgramList();
          });
        }
      });
    }

    getDataForPage(page: number) {
      programListStore.mutateProgramsPayload({ ...programListStore.currentProgramsPayload, page: page });
      this.getProgramList();
    }

    viewProgramDetails(programData: any) : void {
      const programId: string = programData.programId.toString();
      APP_UTILITIES.setCookie('program_id', programId, APP_CONST.ONE);
      programListStore.mutateSitesPayload({ ...programListStore.siteListPayload, id: Number(programId) });
      setTimeout(()=>{
        this.programsModule.RESET_FETCH_PROGRAM_DATA();
        this.$router && this.$router.push(APP_CONST.APP_ROUTES.ACCOUNT_PROGRAMS_SESSIONS)
          .catch((e) => console.log('Error navigating', e));
      }, APP_CONST.ZERO);
    }

    filterColumns(displayColumn: any) {
      let isSelected = false;
      displayColumn && displayColumn.length && displayColumn.forEach((column: any) => {
        if(column.display) {
          isSelected = true;
        }
      });
      this.displayAll = isSelected;
      this.clearAllColumns(this.displayAll);
      programListStore.mutateProgramFilterObject(displayColumn);
      programListStore.mutateProgramSettingsData(displayColumn);
      const storedAccountId: any = APP_UTILITIES.getCookie('accountId');
      programListStore.mutateProgramsPayload({ ...programListStore.currentProgramsPayload, id: parseInt(storedAccountId) });
    }

    clearAllColumns(displayAll:boolean){
      let searchObj:SearchObj = {
        'searchField' : this.programsPayload && this.programsPayload.searchField
          ? this.programsPayload.searchField
          : '',
        'search' : this.programsPayload && this.programsPayload.search
          ? this.programsPayload.search
          : '',
      };
      let sortObjc:SortObjc = {
        'sortField' : this.programsPayload && this.programsPayload.sortField
          ? this.programsPayload.sortField
          : '',
        'sortDir' : this.programsPayload && this.programsPayload.sortDir
          ? this.programsPayload.sortDir
          : 0
      };
      const isSearchFieldFixed = this.tableHeaders.FIXED.map((e:any)=> {
        return e.value; 
      }).indexOf(searchObj.searchField);
      const isSortFieldFixed = this.tableHeaders.FIXED.map((e:any)=> {
        return e.value; 
      }).indexOf(sortObjc.sortField);
      if(!displayAll && isSearchFieldFixed == APP_CONST.MINUS_ONE){
        searchObj={
          'searchField':  this.programsPayload && this.programsPayload.searchField == APP_CONST.PROGRAM_NAME
            ? this.programsPayload.searchField
            : '',
          'search' : this.programsPayload && this.programsPayload.searchField == APP_CONST.PROGRAM_NAME
            ? this.programsPayload.search
            : '',
        };
        programListStore.mutateProgramsSearchObj({ ...this.programsSearchObj, programType: '', noOfScholar: '', enrolledScholars: '', programStatus: '', noOfSites: ''});
        programListStore.mutateProgramsPayload({ ...this.programsPayload ,searchField: searchObj.searchField, search: searchObj.search, sortField: sortObjc.sortField, sortDir: sortObjc.sortDir,});
        programListStore.mutateAllProgramListdata({ ...this.currentProgramListPayloadForFullData ,searchField: searchObj.searchField, search: searchObj.search, sortField: sortObjc.sortField, sortDir: sortObjc.sortDir,});
        this.clearSelection();
        programListStore.getFullProgramsData().then(() => {
          this.getProgramList();
        });

      }
      if(!displayAll && isSortFieldFixed == APP_CONST.MINUS_ONE){
        sortObjc={
          'sortField':this.programsPayload && this.programsPayload.sortField == APP_CONST.PROGRAM_NAME
            ? this.programsPayload.sortField
            : '',
          'sortDir': this.programsPayload && this.programsPayload.sortField == APP_CONST.PROGRAM_NAME
            ? this.programsPayload.sortDir
            : APP_CONST.ZERO
        };
        programListStore.mutateSortPayload({ ...this.programsPayload , sortField: sortObjc.sortField, sortDir: sortObjc.sortDir});
        programListStore.mutateProgramsSearchObj({ ...this.programsSearchObj, programType: '', noOfScholar: '', enrolledScholars: '', programStatus: '', noOfSites: '' });
        programListStore.mutateProgramsPayload({ ...this.programsPayload ,searchField: searchObj.searchField, search: searchObj.search, sortField: sortObjc.sortField, sortDir: sortObjc.sortDir,});
        programListStore.mutateAllProgramListdata({ ...this.currentProgramListPayloadForFullData ,searchField: searchObj.searchField, search: searchObj.search, sortField: sortObjc.sortField, sortDir: sortObjc.sortDir,});
        this.clearSelection();
        programListStore.getFullProgramsData().then(() => {
          this.getProgramList();
        });
      }
    }

    filterDisplayColumn(column:any){
      let searchObj:SearchObj = {
        'searchField' : this.programsPayload && this.programsPayload.searchField
          ? this.programsPayload.searchField
          : '',
        'search' : this.programsPayload && this.programsPayload.search
          ? this.programsPayload.search
          : '',
      };
      let sortObjc:SortObjc = {
        'sortField' : this.programsPayload && this.programsPayload.sortField
          ? this.programsPayload.sortField
          : '',
        'sortDir' : this.programsPayload && this.programsPayload.sortDir
          ? this.programsPayload.sortDir
          : 0
      };
      if(!column.display){
        if(column.value == this.programsPayload.searchField){
          searchObj={
            'searchField': '',
            'search' : '',
          };
          const columnName = column.value;
          programListStore.mutateProgramsSearchObj({ ...this.programsSearchObj, [columnName]: '' });
          programListStore.mutateProgramsPayload({ ...this.programsPayload ,searchField: searchObj.searchField, search: searchObj.search});
          programListStore.mutateAllProgramListdata({ ...this.currentProgramListPayloadForFullData ,searchField: searchObj.searchField, search: searchObj.search});
          this.clearSelection();
          programListStore.getFullProgramsData().then(() => {
            this.getProgramList();
          });
        }
        if(column.value == this.programsPayload.sortField){
          sortObjc={
            'sortField':'',
            'sortDir': 0,
          };
          programListStore.mutateSortPayload({ ...this.programsPayload , sortField: sortObjc.sortField, sortDir: sortObjc.sortDir});
          programListStore.mutateProgramsPayload({ ...this.programsPayload ,sortField: sortObjc.sortField, sortDir: sortObjc.sortDir,});
          programListStore.mutateAllProgramListdata({ ...this.currentProgramListPayloadForFullData ,sortField: sortObjc.sortField, sortDir: sortObjc.sortDir,});
          this.clearSelection();
          programListStore.getFullProgramsData().then(() => {
            this.getProgramList();
          });
        }
      }
    }

    presistResize(resize: { column: any; maxWidth: any; minWidth: any }) {
      programListStore.mutateProgramResizeData(resize);
    }
    sortData(sortObj: { sortField: string; sortDirection: number }){
      programListStore.mutateSortPayload({ ...this.programsPayload , sortField: sortObj.sortField, sortDir: sortObj.sortDirection, page: APP_CONST.ONE});
      programListStore.mutateProgramsPayload({ ...this.programsPayload , sortField: sortObj.sortField, sortDir: sortObj.sortDirection, page: APP_CONST.ONE});
      this.programListResponse = this.isLoaderVisible
        ?false
        :this.programListResponse;
      this.clearSelection();
      programListStore.mutateAllProgramListdata({ ...this.currentProgramListPayloadForFullData , sortField: sortObj.sortField, sortDir: sortObj.sortDirection, page: APP_CONST.ONE});
      programListStore.getFullProgramsData().then(() => {
        this.getProgramList();
      });
    }

    searchData(search:{searchField: string; isExactMatch: boolean}){
      if(search.searchField){
        programListStore.mutateProgramsSearchObj({ ...this.programsSearchObj, [search.searchField]: (this.programsSearchObj && this.programsSearchObj[search.searchField]
          ? this.programsSearchObj[search.searchField]
          : ''), isExactMatch: search.isExactMatch });
      }
      else{
        programListStore.mutateProgramsSearchObj({ ...this.programsSearchObj, programName: '', programType: '', noOfScholar: '', enrolledScholars: '', programStatus: '', noOfSites: '' , isExactMatch: false});
      }

      programListStore.mutateProgramsPayload({ ...this.programsPayload, searchField: search.searchField, search: (this.programsSearchObj && this.programsSearchObj[search.searchField]
        ? this.programsSearchObj[search.searchField]
        : ''), page: APP_CONST.ONE, isExactMatch: search.isExactMatch});
      this.programListResponse = this.isLoaderVisible
        ?false
        :this.programListResponse;
      this.clearSelection();
      programListStore.mutateAllProgramListdata({ ...this.currentProgramListPayloadForFullData, searchField: search.searchField, search: (this.programsSearchObj && this.programsSearchObj[search.searchField]
        ? this.programsSearchObj[search.searchField]
        : ''), page: APP_CONST.ONE, isExactMatch: search.isExactMatch});
      programListStore.getFullProgramsData().then(() => {
        this.getProgramList();
      });
    }

    setProgramTypeValues(values: any[]){
      const idx = this.searchLists.findIndex((list:any) => {
        return list.header == 'programType';
      });
      /* istanbul ignore next */
      if (idx >= APP_CONST.ZERO ) {
        this.searchLists[idx].values = values;
      }
    }

    destroyed() {
      tableStore.mutateSelectAll(false);
      this.clearSelection();
    }

}