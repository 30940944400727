import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class ActionIgnoredPopup extends Vue {

    @Prop({default: 'accountsList'})
    component!: string;

    @Prop({default: 'Account Name'})
    tableHeader!: string;

    @Prop()
    records!: string[];

    @Prop()
    instructions!: {};

    close() {
      this.$emit('closePopup');
    }

}