import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import APP_UTILITIES from '@/utilities/commonFunctions';
import { addNewSession, getPagedSessionsForProgram, updateSession } from '@/services/sessions/api';
import { AddSessionPayload, EditSessionPayload, GetSessionsRequest, GetSessionsResponse, Session } from '@/Model/sessions/types';
import { ApiErrorResponse } from '@/Model/errors/api/types';
import { ErrorMessages } from '@/lang/errorText';
import store from '@/store';
import MutationNames from './mutationNames';
import GlobalMutationNames from '@/store/global/mutationNames';

const {
  SUPRESS_GLOBAL_ERROR_POPUP
} = GlobalMutationNames;

const {
  FETCH_SESSIONS_REQUESTED,
  FETCH_SESSIONS_SUCCEEDED,
  FETCH_SESSIONS_FAILED,
  RESET_FETCH_SESSIONS,
  SET_SESSIONS,
  CLEAR_SESSIONS
} = MutationNames;

const {
  SAVE_SESSION_REQUESTED,
  SAVE_SESSION_SUCCEEDED,
  SAVE_SESSION_FAILED,
  RESET_SAVE_SESSION,
} = MutationNames;

@Module({
  namespaced: true,
  name: 'sessions',
  store,
  dynamic: true
})
export class SessionsModule extends VuexModule {
  sessions: Array<Session> = [];
  totalSessionCount: number = 0;
  isFetchingSessions = false;
  didFetchSessionsFail = false;
  fetchSessionsFailedError: ApiErrorResponse | null = null;

  isSavingSessions = false;
  didSessionsSaveFail = false;
  sessionSaveFailedError: ApiErrorResponse | null = null;

  errorMessages = new ErrorMessages();

  @Mutation
  [FETCH_SESSIONS_REQUESTED]() {
    this.isFetchingSessions = true;
    this.didFetchSessionsFail = false;
    this.fetchSessionsFailedError = null;
  }

  @Mutation
  [FETCH_SESSIONS_SUCCEEDED]() {
    this.isFetchingSessions = false;
    this.didFetchSessionsFail = false;
    this.fetchSessionsFailedError = null;
  }

  @Mutation
  [FETCH_SESSIONS_FAILED](error: ApiErrorResponse) {
    this.isFetchingSessions = false;
    this.didFetchSessionsFail = true;
    this.fetchSessionsFailedError = error;
  }

  @Mutation
  [RESET_FETCH_SESSIONS]() {
    this.isFetchingSessions = false;
    this.didFetchSessionsFail = false;
    this.fetchSessionsFailedError = null;
  }

  @Mutation
  [SET_SESSIONS](getSessionResponse: GetSessionsResponse) {
    this.sessions = getSessionResponse.results;
    this.totalSessionCount = getSessionResponse.totalSetCount;
  }

  @Mutation
  [CLEAR_SESSIONS]() {
    this.totalSessionCount = 0;
    this.sessions = [];
  }

  @Mutation
  [SAVE_SESSION_REQUESTED]() {
    this.isSavingSessions = true;
    this.didSessionsSaveFail = false;
    this.sessionSaveFailedError = null;
  }

  @Mutation
  [SAVE_SESSION_SUCCEEDED]() {
    this.isSavingSessions = false;
    this.didSessionsSaveFail = false;
    this.sessionSaveFailedError = null;
  }

  @Mutation
  [SAVE_SESSION_FAILED](error: ApiErrorResponse) {
    this.isSavingSessions = false;
    this.didSessionsSaveFail = true;
    this.sessionSaveFailedError = error;
  }

  @Mutation
  [RESET_SAVE_SESSION]() {
    this.isSavingSessions = false;
    this.didSessionsSaveFail = false;
    this.sessionSaveFailedError = null;
  }

  @Action
  async fetchSessionsForProgram(request: GetSessionsRequest) {
    try {
      this.FETCH_SESSIONS_REQUESTED();
      const response = await getPagedSessionsForProgram(request);

      this.SET_SESSIONS(response.data);
      this.FETCH_SESSIONS_SUCCEEDED();
      return response;
    }
    catch (error) {
      const apiErrorResponse: ApiErrorResponse = APP_UTILITIES.errorToApiErrorResponse(error);
      this.FETCH_SESSIONS_FAILED(apiErrorResponse);
    }
  }

  @Action
  async addNewSession(newSession: AddSessionPayload) {

    try {
      this.context.commit(`globalModule/${SUPRESS_GLOBAL_ERROR_POPUP}`, true, { root: true });
      this.SAVE_SESSION_REQUESTED();

      await addNewSession(newSession);

      this.SAVE_SESSION_SUCCEEDED();
    }
    catch (error) {
      const apiErrorResponse: ApiErrorResponse = APP_UTILITIES.errorToApiErrorResponse(error);
      this.SAVE_SESSION_FAILED(apiErrorResponse);
    }
    finally {
      this.context.commit(`globalModule/${SUPRESS_GLOBAL_ERROR_POPUP}`, false, { root: true });
    }
  }

  @Action
  async updateSession(payload: EditSessionPayload) {
    try {
      this.context.commit(`globalModule/${SUPRESS_GLOBAL_ERROR_POPUP}`, true, { root: true });
      this.SAVE_SESSION_REQUESTED();
      const response = await updateSession(payload);
      this.SAVE_SESSION_SUCCEEDED();
      return response;
    }
    catch (error) {
      const apiErrorResponse: ApiErrorResponse = APP_UTILITIES.errorToApiErrorResponse(error);
      this.SAVE_SESSION_FAILED(apiErrorResponse);
    }
    finally {
      this.context.commit(`globalModule/${SUPRESS_GLOBAL_ERROR_POPUP}`, false, { root: true });
    }
  }

  @Action
  async resetNewSession() {
    this.RESET_SAVE_SESSION();
  }
}

export default getModule(SessionsModule);