import { Vue, Component, Prop, PropSync, Watch } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import programListStore from '@/store/modules/programList';
import UIkit from 'uikit';

@Component
export class CancelConfirmation extends Vue {
    private objScreenText: ScreenText = new ScreenText();

    @Prop()
    title! : string;

    @Prop({default: 'clearInfo'})
    instructionFor!: string;

    @Prop()
    programsCount!:number;

    @Prop({default: 'cancelPopup'})
    component!: string;

    @Prop({ default: false })
    hasContinueEvent!: boolean;

    @PropSync('isVisible') isVisibleSync !: boolean;

    @Watch('isVisible', { immediate: true })
    onIsVisible(isVisible: boolean) {
      const isElementShowing = UIkit.modal(this.$el).isToggled();

      if (isVisible && isElementShowing === false) {
        UIkit.modal(this.$el).show();
      }
      else if (isVisible === false && isElementShowing) {
        UIkit.modal(this.$el).hide();
      }
    }

    mounted() {
      UIkit.util.on(this.$el, 'hidden', ((event: Event) => {
        this.isVisibleSync = false;
        this.$emit('onClose', event);
      }));

      UIkit.util.on(this.$el, 'beforehide', ((event: Event) => {
        this.$emit('onBeforeClose', event);

        if (event.defaultPrevented && this.isVisibleSync === false) {
          this.isVisibleSync = true;
        }
      }));
    }

    public getScreenText(key: string): string {
      return this.objScreenText.getScreenText(key);
    }

    /* istanbul ignore next */
    get previousRouteDetails(){
      return programListStore.previousRouteDetails;
    }

    continueCancel(){
      if(this.instructionFor=='resetTasks' || this.hasContinueEvent){
        this.$emit('continue');
      }
      else{
        this.$router.push(this.previousRouteDetails.returnTo);
      }
    }

    closePopup() {
      this.$emit('closePopup');
    }
}