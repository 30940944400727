






















































































import Programs from './Programs';
import AddEditProgram from './AddEditProgram.vue';
export default Programs;
